<script>
export default {
	name: 'UiHead',
	props: {
		tag: {
			type: String,
			default: 'h2',
		},
		variant: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			classes: {
				'h2-primary': `font-semibold text-[24px] text-second`,
				'h5-primary': `font-bold text-[16px] text-second`,
			},
		}
	},
	computed: {
		getClasses() {
			return this.classes[`${this.tag}-${this.variant}`]
		},
	},
}
</script>

<template>
	<component :is="tag" :class="{ [getClasses]: true }" class="font-main">
		<slot />
	</component>
</template>

<style>
.main-logo span strong {
	font-weight: 800 !important;
	font-size: 12px;
	line-height: 0.9;
	text-transform: uppercase;
}
</style>
