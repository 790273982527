<script>
export default {
	name: 'MainContainer',
}
</script>

<template>
	<div class="w-full box-border m-auto container">
		<slot />
	</div>
</template>

<style></style>
