<script>
import MainContainer from '@/components/main/MainContainer.vue'
import UiHead from '@/components/UI/atoms/UiHead.vue'

export default {
	components: {
		MainContainer,
		UiHead,
	},
	data() {
		return {
			faqActive: 0,
		}
	},
	methods: {
		faqClick: function (index) {
			if (this.faqActive == index) this.faqActive = null
			else this.faqActive = index
		},
	},
	head: {
		title() {
			return {
				inner: this.$t('site.home.faq.title'),
			}
		},
		meta: function () {
			return [{ name: 'description', content: this.$t('site.recovery.description') }]
		},
	},
}
</script>

<template>
	<div class="wrap__content pb-[60px]">
		<MainContainer class="relative pt-[20px]">
			<div class="mb-[40px] md:mb-[60px]">
				<UiHead>
					{{ $t('site.home.faq.title') }}
				</UiHead>
			</div>

			<div class="flex flex-col items-stretch gap-[10px]">
				<div
					class="relative overflow-hidden shadow-xl rounded-[45px] bg-white"
					v-for="(item, index) in $t('site.faqList')"
					:key="index"
				>
					<div class="px-[25px] py-[40px]" @click.stop="faqClick(index)">
						<div
							class="flex items-center gap-x-[10px] font-main font-semibold text-[16px] pr-[65px] text-second md:text-[18px] lg:text-[20px]"
						>
							<img src="@/assets/svgs/message-question.svg" alt="" />
							<span>{{ item.title }}</span>
						</div>
						<div
							class="absolute bottom-[-32px] right-[-10px] w-[93px] h-[93px] rounded-full bg-[#157fee]"
						></div>
						<div
							class="block absolute z-1 bottom-[18px] right-[28px] cursor-pointer transform origin-center duration-300"
							:class="{
								'-rotate-90': faqActive == index,
								'rotate-90': faqActive != index,
							}"
						>
							<img src="@/assets/svgs/link-arrow-circle-right.svg" alt="" class="block" />
						</div>
					</div>
					<transition name="slide">
						<div v-if="faqActive == index">
							<div
								class="font-main font-normal text-[16px] text-second px-[25px] pb-[40px] pr-[65px]"
								v-html="item.desc"
							></div>
						</div>
					</transition>
				</div>
			</div>
		</MainContainer>
	</div>
</template>

<style></style>
